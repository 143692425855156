.Hotel {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.background-box {
    background-color: #363636;
    color: #ffffff;
    height: 237px;
    width: 100%;
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.title-hotel {
    font-family: 'EB Garamond', serif;
    font-size: 23px;
    font-weight: 500;
    margin-top: -60px;

}

.image-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin-top: -120px;
    place-items: center;
}

.brideandgroom2 {
    height: 100%;
    width: auto;
    /*object-fit: cover;
    position: relative;*/
}

.discount {
  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'LATO', sans-serif;
    font-size: 14px;
    letter-spacing: .12rem;
    line-height: 24px;
    padding-left: 65px;
    padding-right: 65px;
    margin-bottom: 25px;

}

.title-discount {
    text-transform: uppercase;
    font-weight: 400;

}

.a{
    color: black;
    font-weight: 600;
}

@media only screen and (min-width: 768px) {
   .title-hotel{
    font-size: 30px;

   }

   .discount{
    font-size: 18px;
   }

   .background-box{
        margin-top: 15px;
        margin-bottom: 0px;
        width: 89%;
        margin-left: 58px;
        margin-right: 58px;
        font-size: 24px;
        
   }


}
@media only screen and (min-width: 1024px) {
    .image-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        margin-top: -120px;
        place-items: start center;
    }
    .brideandgroom2 {
        height: auto;
        width: auto;
    
        }
         .discount{
            font-size: 18px;
            line-height: 30px;
            margin-top: -60px;
            align-self: end;
           }
        

}
