.Date-time {
    font-family: 'Lato', serif;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    letter-spacing: .14rem;
    text-transform: uppercase;
    line-height: 1.7rem;
    margin-top: 50px;
}

@media only screen and (min-width: 768px) {
.Date-time{
    font-size: 18px;
}
}