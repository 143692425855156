.container {
    width: 100%;
    overflow-x: scroll;
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 95px;
    grid-auto-flow: column;
    overflow-x: scroll;
  }
  
  .item {
    text-align: center;
  }
  
  .item img {
    max-height: 100px;
    width: auto;
  }

  .arrow{
    margin-left: -130px;

  }

  .rings{
    margin-left: 25px;
  }
  .sparkler{
    margin-right: 35px;
  }

  .scroller-time{
    font-family: 'Times New Roman', Times, serif;
    font-size: 14px;
    font-weight: bold;
    margin: none;

  }
  .scroller-title {
    margin-top: 5px;
    font-size: 14px;
    font-family: 'Lato', serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .1rem;


  }

  .party-info{
    display:none;
  }

  @media only screen and (min-width: 768px) {
 
    .item{
      display: none;
    }
  
    .party-info{
      display: block;
      margin-right: 58px;
      margin-left: 58px;
      font-family: 'LATO', sans-serif;
      font-size: 18px;
      letter-spacing: .12rem;
      line-height: 25px;
      text-transform: uppercase;
      padding-top: 10px;
      padding-bottom: 10px;

    }

  }

