body {
    background-color: #F9F5F2;
}

.sub-header {
    margin-top: 70px;
    font-family: 'Lato', serif;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .1rem;
}


.title {
   font-family: 'EB Garamond', serif;
   font-weight: 500;
   font-size: 35px;
   text-transform: uppercase;
}

.address, .rsvp {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    letter-spacing: .14rem;
    text-transform: uppercase;
    line-height: 1.7rem;
    margin-top: 50px;
}

.rsvp {
    font-weight: 500;
    margin-top: 30px;
    margin-bottom: 80px;
}

.toastmaster-container {
    margin-top: 30px;
    margin-right: 58px;
    margin-left: 58px;
    border: solid #000;
    border-width: 1px;
    font-family: 'LATO', sans-serif;
    font-size: 14px;
    letter-spacing: .12rem;
    padding-top: 10px;
    padding-bottom: 10px;


}

.title-toastmaster {
    text-transform: uppercase;
    margin-bottom: 7px;

}

.name-toastmaster{
    text-transform: uppercase;
    font-weight: 600;
}
.toastmaster-contactinfo {
    margin-top: 8px;
    line-height: 1.3rem;
}

.side-note {
    font-family: 'Lato', serif;
    font-size: 15px;
    background-color: #DDD7D3;
    font-style: italic;
    font-weight: 400;
    margin-right: 58px;
    margin-left: 58px;
    margin-bottom: 50px;
    padding: 15px;
    letter-spacing: .08rem;
}


.allergies{
    margin-left: 55px;
    margin-right: 55px;
}

.footerphoto{
    display: none;
}


@media only screen and (min-width: 768px) {
    .title{
        font-size: 60px;
    }

    .sub-header{
        font-size: 18px;
    }
    
    .address, .rsvp{
        font-size: 18px;
    }

    .toastmaster-container{
        padding-top: 100px;
        font-size: 18px;
        background-color: #DDD7D3;
        border: none;
    }
    .toastmaster-contactinfo{
        line-height: 37px;
    }
    .side-note{
        margin-top: 0px;
        margin-bottom: 0px;
        font-size: 18px;
        padding-bottom: 100px;

    }
    .brideandgroom{
        display: none;
    }

    .thenightbefore{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-top: -50px;
    }

    .brideandgroom3{
        grid-column: 3;
        justify-self: center;

    }

    .allergies{
        margin-top: -40px;
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 15px;
    }

    .footerphoto{
        display: unset;
        height: auto;
        width: 89%;
        margin-bottom: 30px;
    }
}